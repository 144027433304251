import { render, staticRenderFns } from "./userOverview.vue?vue&type=template&id=54f5f571&scoped=true&"
import script from "./userOverview.vue?vue&type=script&lang=js&"
export * from "./userOverview.vue?vue&type=script&lang=js&"
import style0 from "./userOverview.vue?vue&type=style&index=0&id=54f5f571&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54f5f571",
  null
  
)

export default component.exports