<template>
  <section class="full-height">

    <div class="columns is-centered is-gapless is-marginless">
      <div class="column is-mobile is-narrow mt2rem mb2rem">
        <div class="has-text-grey is-size-2 is-size-4-mobile wa-underlined-title">Users</div>
      </div>
    </div>
    
    <div class="is-mobile is-gapless is-centered is-multiline m2rem">
        <h1>All Users ({{ users.length}})</h1>
        <b-field v-show="showUserData" label="filter">
          <b-input v-model="companyUserFilterStr" maxlength="20000" 
                  placeholder="filter on email or userId or big" 
                  type="textarea" @input="filterUserData">
          </b-input>
        </b-field>
         <div v-for="(filterIssues, key) in userFitlerIssues" v-bind:key="`filterIssues_${key}_index`" style='color: red;margin:5px;'>
          {{ filterIssues }}
        </div>
        <b-table
            v-show="showUserData"
            :data="usersFiltered"
            :bordered="true"
            :striped="true"
            :narrowed="true"
            :loading="tableIsLoading"
            
            :paginated="isPaginated"
            :current-page.sync="currentPage"
            :pagination-position="paginationPosition"
            :per-page="perPage"
            
            :mobile-cards="true">

            <b-table-column field="user_id" label="user id" width="80" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.user_id }}
            </b-table-column>            
            
            <b-table-column field="companies" label="company companyUserId" width="180" :td-attrs="columnTdAttrs" v-slot="props" sortable centered searchable>
                <div v-for="(compUser, key) in props.row.companyUserIdArr" v-bind:key="`companies_${key}_index`">
                  <!-- <a :href='`${WEB_ROOT}/company/${compUser.companyId}/user/${props.row.user_id}`' target='_blank'> -->
                    {{ compUser.companyId }}-{{ compUser.companyUserId }}-{{ compUser.roles.join(`,`)}}
                  <!-- </a> -->
                </div>
            </b-table-column>            
            
            <b-table-column field="email" label="email" width="80" :td-attrs="columnTdAttrs" v-slot="props" sortable centered searchable>
                {{ props.row.users_email }}
            </b-table-column>
            
            <b-table-column field="users_name" label="name" width="80" :td-attrs="columnTdAttrs" v-slot="props" sortable centered searchable>
                {{ props.row.profiles_first_name }} {{ props.row.profiles_insertion }} {{ props.row.profiles_last_name }}
            </b-table-column>
            
            <b-table-column field="profiles_mobile" label="mobile" width="80" :td-attrs="columnTdAttrs" v-slot="props" sortable centered searchable>
                {{ props.row.profiles_mobile }}
            </b-table-column>
            
            <b-table-column field="big_numbers" label="big" width="80" :td-attrs="columnTdAttrs" v-slot="props" sortable centered searchable>
                <div v-for="(bignr, key) in props.row.bignumberArr" v-bind:key="`bignr_${key}_index`">
                  {{ bignr }}
                </div>
            </b-table-column>

            <template #empty>
                <div class="has-text-centered">No records</div>
            </template>

        </b-table>
        
        <b-field label="per page">
            <b-select v-model="perPage">
              <option value="1">1 per page</option>
              <option value="5">5 per page</option>
              <option value="10">10 per page</option>
              <option value="25">25 per page</option>
              <option value="50">50 per page</option>
              <option value="250">250 per page</option>
              <option value="500">500 per page</option>
              <option value="2000">2000 per page</option>
              <option value="10000">10000 per page</option>
          </b-select>
        </b-field>
        
        <!-- <b-button :type="'is-success'" @click="copyToClipboard('company_user_id')">copy companyUserId</b-button>
        <b-button :type="'is-success'" @click="copyToClipboard('user_id')">copy userId</b-button>
        <b-button :type="'is-success'" @click="copyToClipboard('users_email')">copy email</b-button>
        <b-button :type="'is-success'" @click="copyToClipboard('profiles_mobile')">copy mobile</b-button>        
        <b-button :type="'is-success'" @click="copyToClipboard('bignumbers')">copy big</b-button>        -->
        
      </div>
    
   
    
    <b-notification :closable="false">
      <b-loading :is-full-page="true" :active.sync="pageLoading"></b-loading>
    </b-notification>
  </section>
</template>

<script>

import axios_api from '@/plugins/axios_api';
import { API_ROOT, WEB_ROOT } from '@/config/app.js';
// import MultiSelect from "@/components/public/general/multiSelect.vue";
// import MultiSlider from "@/components/public/general/multiSlider.vue";
// import SearchInput from "@/components/public/general/searchInput.vue";

export default {
  name: "AllUserOverview", 
  
  components: {
      // MultiSelect,
      // MultiSlider,
      // SearchInput
    },
         
  data() {
    return {
      pageLoading         : false,
      pageLoadingTimeout  : null,
  
      pageTitle           : 'Users',
      json                : null,
      users               : [],      
      usersFiltered       : [],
      
      userFitlerIssues    : [],
      tableIsLoading      : false,
      
      isPaginated         : true,
      paginationPosition  : 'bottom',
      defaultSortDirection: 'asc',
      currentPage         : 1,
      perPage             : 10,
      
      showUserData: true,
      companyUserFilterStr : '',

      WEB_ROOT            : WEB_ROOT,
      API_ROOT            : API_ROOT,
      SITE_ROOT           : process.env.VUE_APP_SITE_MAIN,
      
    }
  },
  
  async mounted(){
    // this.ShowPageLoading(15000);
    // this.userstats = await this.$store.dispatch('databaseactions/DB_GetUsers'); 
    await this.getUsers()
    // console.info(`userstats:`, this.userstats);
  },
  
  methods: {
    
    async getUsers(){
      // this.ShowPageLoading(15000)
      try {
        let response = await axios_api.get(`${API_ROOT}/schedule/allusers`);
        // console.info(`users:`, response.data)
        this.json = response.data.payload.userData
        this.processUsers(this.json)
        this.filterUserData()
        // console.info(`usersFiltered:`, this.users)
        // console.info(`users processed:`, this.users)
      } catch (err) {
        this.json = {}
      }
    },
    processUsers(users) {
      let uList = {}
      for(let user of users) {
        if (typeof(uList[user.user_id]) === "undefined") {
          uList[user.user_id] = user
          uList[user.user_id].companyIdSet   = new Set([])
          uList[user.user_id].companyUserIdSet   = new Set([])
          uList[user.user_id].departmentSet  = new Set([])
          uList[user.user_id].bignumberSet   = new Set([])
          uList[user.user_id].roleSet        = new Set([])
        }
        if (user.company_id) {
          uList[user.user_id].companyIdSet.add(user.company_id)
          uList[user.user_id].companyUserIdSet.add({
            companyId: user.company_id, 
            companyUserId: user.company_user_id,
            roles: user.role_names ? user.role_names.split(`,`) : [],
            departments: user.departments ? user.departments.split(`,`) : []
          })
        }
        
        if (user.big_numbers) {
          let big_numbers = user.big_numbers.split(`,`)
          for(let big_number of big_numbers) {
            uList[user.user_id].bignumberSet.add(big_number)
          }
        }
        
        if (user.departments) {
          let departments = user.departments.split(`,`)
          for(let department of departments) {
            uList[user.user_id].departmentSet.add(department)
          }
        }
        
        if (user.role_names) {
          let role_names = user.role_names.split(`,`)
          for(let role_name of role_names) {
            uList[user.user_id].roleSet.add(role_name)
          }
        }
      }
      // console.info(`uList:`, uList)
      
      //return as array
      let rtnList = []
      for(let key in uList) {
        // console.info(`key:`, key)
        uList[key].companyIdArr     = [...uList[key].companyIdSet]
        uList[key].companyUserIdArr = [...uList[key].companyUserIdSet]
        uList[key].departmentArr    = [...uList[key].departmentSet]
        uList[key].bignumberArr     = [...uList[key].bignumberSet]
        uList[key].roleArr          = [...uList[key].roleSet]
        rtnList.push(uList[key])
      }
      // console.info(`rtnList:`, rtnList)
      this.users = rtnList
      // return rtnList      
    },
    
    filterUserData(){
      if (this.companyUserFilterStr.trim() === ``) {
        this.usersFiltered = this.users
        this.userFitlerIssues = []      
        return
      }
      
      let filterStr = this.companyUserFilterStr.toString().toLowerCase()
      // console.info(`filterStr:`, filterStr)
      let lines = filterStr.split(`\n`)
      // console.info(`lines:`, lines)
      let issues = []
      let filterList = []
      
      for(let line of lines) {
        line = line.trim()
        if (line === ``) continue
        console.info(`checking line: -${line}-`)
        
        if (line.indexOf(`@`) > -1 || isNaN(parseInt(line)) ) {
          console.info(`cehcking??`)
          const find = this.users.filter(u => {
            return  u.users_email.toLowerCase().indexOf(line) > -1 ||
                    (u.users_name && u.users_name.toLowerCase().indexOf(line) > -1) ||
                    (u.profiles_first_name && u.profiles_first_name.toLowerCase().indexOf(line) > -1) ||
                    (u.profiles_last_name && u.profiles_last_name.toLowerCase().indexOf(line) > -1)
          })
          console.info(`find line --${line}--:`, find)
          if (find.length < 1) {
            issues.push(`unable to find: ${line}`)
            continue
          } else if (find.length > 1) {
            issues.push(`multiple find for find: ${line}`)
            continue
          } else if (filterList.find(u => u.user_id === find.user_id)) {
            issues.push(`duplicate find: ${line}`)
            continue
          }
          filterList.push(find[0])
          continue
        }
        
        let number = parseInt(line)
        if (isNaN(number) || number <= 0) {
          issues.push(`invalid number ${line}`)
          continue
        }
        
        const findUserId  = this.users.find(u => u.user_id === number)
        // console.info(`this.userData:`, this.userData)
        const findBig     = this.users.find(u => u.bignumberSet.has(number))
        
        if (!findUserId && !findBig) {
          issues.push(`unable to find userId/bignumber: ${line}`)
          continue
        } 
        
        if (findUserId) {
          if (filterList.find(u => u === findUserId)) {
            issues.push(`duplicate number: ${line}`)
            continue
          }
          filterList.push(findUserId)
          continue
        }
        
        if (findBig) {
          if (filterList.find(u => u === findBig)) {
            issues.push(`duplicate bignumber: ${line}`)
            continue
          }
          filterList.push(findBig)
          continue
        }
        
      }
      
      console.info(`issues:`, issues)
      this.usersFiltered = filterList
      this.userFitlerIssues = issues
      
    },
    
    async ShowPageLoading(maxDuration = 30000){
      this.pageLoading = true;
      
      if (this.pageLoadingTimeout !== null) {
        clearTimeout(this.pageLoadingTimeout);
      }
      
      this.pageLoadingTimeout = setTimeout(() => {
        this.HidePageLoading();
      }, maxDuration);
    },
    
    async HidePageLoading() {
      this.pageLoading = false;
      if (this.pageLoadingTimeout !== null) {
        clearTimeout(this.pageLoadingTimeout);
      }
    },
    
    /* eslint-disable-next-line */        
    dateThAttrs(column) {
      return null
    },
            
    /* eslint-disable-next-line */
    columnTdAttrs(row, column) {
      return null
    },
        
    
  },
};
</script>

<style scoped>
  .hasError{
    color: red;
  }
  
  .clickable{
    cursor: pointer;
  }
  .clickable:hover{
    color: #AEAEAE;
  }
  
  .itemtext{
    float:left;
    display: inline;
    min-width: 80px;
    font-weight: bold;
    /* background-color: orange;; */
  }
  
  
  .HashStatusOK,
  .GitStatusOK{
    color:darkgreen;
  }
  
  .HashStatusFailed,
  .GitStatusFailed{
    color: red;
  }
                        
</style>